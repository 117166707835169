import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CampusLocationInterface } from '../interfaces/CampusLocationInterface';

export enum UpdateModelStatesEnum {
  FORCE = 'force',
  PROMPT = 'prompt',
}

export const faqData: FaqScreenData[] = [
  {
    index: 1,
    title: 'How do I earn points?',
    about:
      '1. Dine at our partner restaurants and pay the bill on the 32nd Club app, to earn points 10% worth the bill value. \n 2. You earn 10 points every time you park your car at 32nd Avenue.',
  },
  {
    index: 2,
    title: 'How do I redeem the points that I have earned?',
    about:
      'Dine at our partner restaurants and redeem your points to get discounts on the total bill amount. For this, you need to pay the bill using the 32nd Club app.',
  },
  {
    index: 3,
    title: 'How much are my points worth?',
    about:
      '1 point = ₹1\n' +
      'The points you earn are for redemption without any terms or conditions. Use your points at our partner restaurants and enjoy the discounts!',
  },
  {
    index: 4,
    title: 'Is there any expiry of the points that I have acquired?',
    about:
      'The points you earn are valid for a period of 365 days, starting from the date you get them. You can redeem them within this time period.',
  },
  {
    index: 5,
    title: 'Is there any limit on usage?',
    about:
      'The usage of your points is unlimited!\n' +
      'We believe in curating experiences that give unlimited joy to our loyal 32nd Club members.',
  },
  {
    index: 6,
    title: 'When will the cashback points be credited?',
    about:
      'The cashback points will be credited to your account after 24 hours of a successful transaction!',
  },
];

export const faqDataPM: FaqScreenData[] = [
  {
    index: 1,
    title: `How much do I have to pay to get clicked by the photography team at 32nd Avenue?`,
    about:
      'The pictures clicked by our in-house team are absolutely free of cost. All you need to do is spot the 32nd Moments photography team and pose for the camera!',
  },
  {
    index: 2,
    title: 'I am worried about my privacy. Who all can access these photos?',
    about:
      'Your pictures are locked and can only be accessed when you unlock them with your unique face ID. Only you get access to your 32nd Moments.',
  },
  {
    index: 3,
    title: 'Will 32nd post my pictures on their social media accounts?',
    about:
      '32nd believes in keeping every visitor’s safety as the highest priority. We do not share any pictures or videos on our social media without your consent.',
  },
  {
    index: 4,
    title: 'Can I get my pictures clicked on every visit?',
    about:
      'You can get clicked by our in-house photography team as many times as you like. It gives us great joy to capture some of your unforgettable moments at 32nd Avenue.',
  },
];

export enum AppTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface FaqScreenData {
  index: number;
  title: string;
  about: string;
}

export interface ImageModalData {
  url: string;
  _id: string;
}

export interface ClubMemberShipCheckedStatus {
  deniedTime: Date | null;
  checked: boolean;
}

export interface UpdateModal {
  state: boolean;
  status: string;
}

export interface CommonStoreInterface {
  campusId: string;
  showDownloadAppModal: boolean;
  setCampusId: (campusId: string) => void;
  setShowDownloadAppModal: (open: boolean) => void;
  theme: AppTheme;
  wasSignupFlowActive: boolean;
  setAppTheme: (theme: AppTheme) => void;
  faqScreenData: FaqScreenData[];
  setFaqScreenData: (data: FaqScreenData[]) => void;
  hasLoginSkipped: boolean;
  setHasLoginSkipped: (skipped: boolean) => void;
  orientation: number;
  setOrientation: (orientation: number) => void;
  campusDetails: CampusLocationInterface | null;
  setCampusDetails: (details: CampusLocationInterface) => void;
  setWasSignupFlowActive: (active: boolean) => void;
  deniedTime: Date | null;
  setDeniedTime: (time: Date | null) => void;
  checkedClubBenefits: ClubMemberShipCheckedStatus;
  setCheckedClubBenefits: (
    checkedClubBenefits: ClubMemberShipCheckedStatus,
  ) => void;
  faqBottomSheetOpen: boolean;
  setFaqBottomSheetOpen: (faqBottomSheetOpen: boolean) => void;
  shouldUpdate: boolean;
  setShouldUpdate: (shouldUpdate: boolean) => void;
  showUpdateModal: UpdateModal;
  setShowUpdateModal: (updateModal: UpdateModal) => void;
  showInvestNewTag: boolean;
  setShowInvestNewTag: (showInvestNewTag: boolean) => void;
  calanderEventsId: any;
  setCalanderEventsId: (calanderEventsId: any) => void;
}

const useCommonStore = create<CommonStoreInterface>()(
  devtools(
    persist(
      (set) => ({
        campusId: '',
        showDownloadAppModal: false,
        theme: AppTheme.LIGHT,
        faqScreenData: faqData,
        hasLoginSkipped: false,
        orientation: 0,
        campusDetails: null,
        wasSignupFlowActive: false,
        deniedTime: null,
        checkedClubBenefits: {
          deniedTime: null,
          checked: true,
        },
        faqBottomSheetOpen: false,
        shouldUpdate: false,
        showUpdateModal: {
          state: false,
          status: '',
        },
        showInvestNewTag: true,
        calanderEventsId: [],
        setCampusId: (campusId: string) => set(() => ({ campusId })),
        setShowDownloadAppModal: (open: boolean) => {
          set(() => ({ showDownloadAppModal: open }));
        },
        setAppTheme: (theme: AppTheme) => set(() => ({ theme })),
        setFaqScreenData: (data: FaqScreenData[]) => {
          set(() => ({ faqScreenData: data }));
        },
        setHasLoginSkipped: (skipped: boolean) => {
          set(() => ({ hasLoginSkipped: skipped }));
        },
        setOrientation: (orientation: number) => {
          set(() => ({ orientation }));
        },
        setCampusDetails: (details: CampusLocationInterface) => {
          set(() => ({ campusDetails: details }));
        },
        setWasSignupFlowActive: (active: boolean) => {
          set(() => ({ wasSignupFlowActive: active }));
        },
        setDeniedTime: (time: Date | null) => {
          set(() => {
            return { deniedTime: time };
          });
        },
        setCheckedClubBenefits: (data: ClubMemberShipCheckedStatus) => {
          set(() => ({ checkedClubBenefits: data }));
        },
        setFaqBottomSheetOpen: (faqBottomSheetOpen: boolean) => {
          set(() => ({ faqBottomSheetOpen }));
        },
        setShouldUpdate: (shouldUpdate: boolean) => {
          set(() => ({ shouldUpdate }));
        },
        setShowUpdateModal: (data: UpdateModal) => {
          set(() => ({ showUpdateModal: data }));
        },
        setShowInvestNewTag: (showInvestNewTag: boolean) => {
          set(() => ({ showInvestNewTag }));
        },
        setCalanderEventsId: (calanderEventsId: any) => {
          set(() => ({ calanderEventsId }));
        },
      }),
      {
        name: 'CommonStore',
        storage: createJSONStorage(() => AsyncStorage),
      },
    ),
    {
      name: 'CommonStore',
    },
  ),
);

export default useCommonStore;
