/* eslint-disable import/prefer-default-export */
import { graphql, commitMutation, fetchQuery } from 'react-relay';
import environment from '../../../../relay/relayEnvironment';
import { commitMutationPromise } from '../../../../utilities/helper';

export const paymentAmountScreenQuery = graphql`
  query paymentAmountScreenQuery(
    $amount: Float
    $tipAmount: Float
    $csrContributionId: ID
    $useRewardPoints: Boolean!
    $orderId: ID!
    $orderType: Referer!
    $workshopDetails: invoiceWorkshopDetailsInput
    $couponCode: String
    $addons: addonsDetailsInput
    $preAppliedAddon: addonsDetailsInput
    $fetchPreAppliedAddon: Boolean!
  ) {
    getNetPrice(
      amount: $amount
      tipAmount: $tipAmount
      useRewardPoints: $useRewardPoints
      csrContributionId: $csrContributionId
      orderId: $orderId
      orderType: $orderType
      workshopDetails: $workshopDetails
      couponCode: $couponCode
      addons: $addons
    ) {
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
        tipAmount
        csrAmount
        rewardsUsable
        discountPercentage
        couponId
        couponAmount
        couponCashbackAmount
        billSummary {
          grossAmount
          deductions
          additions
          netAmount
        }
        addonAmount
      }
      discountPercentage
      cashbackPercentage
      cashbackAmount
      sourceDetails {
        restaurant {
          displayName
          payBill {
            payEnabled
            pointsEnabled {
              redeem
            }
          }
          _id
        }
        event {
          title
          _id
          eventTiming {
            _id
            slot {
              availableTickets {
                _id
                price
                basePrice
                personAllowed
              }
            }
          }
        }
        store {
          displayName
          _id
        }
        popUpStore {
          displayName
          _id
        }
        parking {
          _id
        }
        parking {
          parkingInvoice {
            charges {
              chargesBreakup {
                duration
                payable
              }
            }
          }
        }
        addons {
          _id
          name
        }
      }
      extras {
        coupons {
          isApplied
          message
        }
      }
    }
    getNetPriceWithMembership: getNetPrice(
      amount: $amount
      tipAmount: $tipAmount
      useRewardPoints: $useRewardPoints
      csrContributionId: $csrContributionId
      orderId: $orderId
      orderType: $orderType
      workshopDetails: $workshopDetails
      couponCode: $couponCode
      addons: $preAppliedAddon
    ) @include(if: $fetchPreAppliedAddon) {
      priceDetails {
        grossAmount
        discountGiven
        rewardsUsed
        netAmount
        tipAmount
        csrAmount
        rewardsUsable
        discountPercentage
        couponId
        couponAmount
        couponCashbackAmount
      }
      discountPercentage
      cashbackPercentage
      cashbackAmount
      sourceDetails {
        restaurant {
          displayName
          payBill {
            payEnabled
            pointsEnabled {
              redeem
            }
          }
          _id
        }
        event {
          title
          _id
          eventTiming {
            _id
            slot {
              availableTickets {
                _id
                price
                basePrice
                personAllowed
              }
            }
          }
        }
        store {
          displayName
          _id
        }
        popUpStore {
          displayName
          _id
        }
        parking {
          _id
        }
        parking {
          parkingInvoice {
            charges {
              chargesBreakup {
                duration
                payable
              }
            }
          }
        }
        parking {
          parkingInvoice {
            charges {
              chargesBreakup {
                duration
                payable
              }
            }
          }
        }
        addons {
          _id
          name
        }
      }
      extras {
        coupons {
          isApplied
          message
        }
      }
    }
  }
`;

export const fetchConfig = (data) => {
  const configQuery = graphql`
    query paymentAmountScreenQueryTipConfigQuery(
      $addonsFilter: FilterFindManyaddonInput
      $userId: MongoID!
      $isLoggedIn: Boolean!
    ) {
      findConfig(filter: {}) {
        payBillConfig {
          tipStatus {
            refType
            isTipEnabled
          }
        }
      }
      findCharities(filter: {}) {
        _id
        title
        description
        isActive
        finePrint {
          details
          summary
        }
        contribution {
          type
          value
        }
      }
      findAddons(filter: $addonsFilter) {
        _id
        name
        price
        type
        additionalCashback {
          refType
          cashback {
            value
            type
          }
        }
        additionalDiscount {
          refType
          discount {
            value
            type
          }
        }
        expiryDuration {
          days
        }
      }
      findUserById(_id: $userId) @include(if: $isLoggedIn) {
        memberships {
          membershipAddonId
          status
          expiryDate
        }
      }
    }
  `;

  const variables = data;
  return fetchQuery(environment, configQuery, variables, {
    force: true,
  }).toPromise();
};

export const initiatePaymentAPI = (data, callback, errorCallback) => {
  const mutation = graphql`
    mutation paymentAmountScreenQueryInitiatePaymentMutation(
      $amount: Float
      $orderType: Referer!
      $orderId: ID!
      $userId: ID
      $csrContributionId: ID
      $paymentType: PaymentType
      $useRewardPoints: Boolean!
      $tipAmount: Float
      $workshopDetails: invoiceWorkshopDetailsInput
      $couponCode: String
      $addons: addonsDetailsInput
    ) {
      initiatePayment(
        amount: $amount
        orderType: $orderType
        orderId: $orderId
        userId: $userId
        paymentType: $paymentType
        useRewardPoints: $useRewardPoints
        tipAmount: $tipAmount
        workshopDetails: $workshopDetails
        couponCode: $couponCode
        csrContributionId: $csrContributionId
        addons: $addons
      ) {
        userId
        displayName
        priceDetails {
          grossAmount
          discountGiven
          rewardsUsed
          netAmount
          tipAmount
          csrAmount
          _id
        }
        status
        _id
        created_at
        orderId
        paymentType
        paymentDetails {
          paytmQr {
            transactionId
            qrImage
          }
          juspayOrder {
            service
            paymentLinks {
              web
            }
            _id
            orderId
            amount
            sdkPayload
          }
        }
      }
    }
  `;

  const variables = data;
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, error) => {
      callback(response, error, data);
    },
    onError: (err) => {
      errorCallback(err);
    },
  });
};

export const updatePaymentAPI = (data) => {
  const mutation = graphql`
    mutation paymentAmountScreenQueryUpdatePaymentMutation(
      $pgOrderId: ID!
      $pgPaymentId: ID
      $pgSignature: String
    ) {
      updatePayment(
        pgOrderId: $pgOrderId
        pgPaymentId: $pgPaymentId
        pgSignature: $pgSignature
      ) {
        userId
        displayName
        priceDetails {
          grossAmount
          discountGiven
          rewardsUsed
          netAmount
          _id
        }
        status
        _id
        created_at
        orderId
      }
    }
  `;

  const variables = data;
  return commitMutationPromise(mutation, variables);
  // commitMutation(environment, {
  //   mutation,
  //   variables,
  //   onCompleted: (response, error) => {
  //     callback(response, error, data);
  //   },
  //   onError: (err) => {
  //     errorCallback(err);
  //   },
};
